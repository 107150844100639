/* import the necessary Bootstrap files */

// overwrite theme variables
$primary: #004b91;
$success: #42f57b;
// $danger: #d2232a;
// $warning: #dac803;

$navbar-height: 10vh;
$footer-height: 21vh;

.theme-navbar {
    min-height: $navbar-height;
    background-color: #0B2240;
    color: #fff;

    // .nav-item {
    //     border-bottom: solid 3px #dac803;
    // }

    .nav-link {
        color: #fff !important;
    }

    .nav-item:hover .nav-link {
        color: #000 !important;
        text-decoration: underline;
    }

    .nav-item:hover {
        text-decoration: none;
        background-color: #fff;
        color: #000;
        border-radius: 3px;
    }
}

#wrapper {
    min-height: 100 - $navbar-height - $footer-height;
}

.theme-footer {
    min-height: $footer-height;
    background-color: #0B2240;
    color: #fff;

    a {
        color: #fff;
    }

    a:hover {
        color: #cecece
    }
}

.brand-logo {
    height: 110px;
    width:  auto;
}

.navbar {
    padding: 0px !important;
}

.swiper-pagination-bullet-active {
  background-color: black !important;
}
.swiper-button-prev {
  color: black !important;
}
.swiper-button-next {
  color: black !important;
}
.swiper-wrapper{
    height: 94% !important; 
}

.justificado {
    text-align: justify;
    a{
        color: #0F2CB3;
        font-weight: bold;
    }
}
.bbl{
    color: #000 !important;
}

.my-pagination .page-link {
  background-color: #fff; 
  color: #000; 
}
  
.my-pagination .page-link:hover {
  background-color: #ffca2c; 
  color: #000; 
}
.page-link.active, .active > .page-link {
    color: #000 !important;  
    background-color: #ffc107 !important;
    border-color: #ecb40a !important;
}

/* finally, import Bootstrap */
@import "~bootstrap/scss/bootstrap.scss"